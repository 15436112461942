<template>
  <TosListVuetify :entityName="entityName" :headers="headers" :validateFunction="validate" >
    <template #customFilters="slotProps">
      <v-container fluid>
        <v-row>
          <v-col cols="3">
            <v-select  :label="tLabel('Tipo Danno')" v-model.number="slotProps.data.tipoDanno___EQ"  :items="tipoDanni" />
          </v-col>
          <v-col cols="3">
            <v-text-field label="Codice Danno" v-model="slotProps.data.codDanno" />
          </v-col>
          <v-col cols="3">
            <v-text-field label="Descrizione Danno" v-model="slotProps.data.descDanno" />
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template #editF="slotProps">
      <div v-if="slotProps.data" class="p-cardialog-content">
        <v-row>
            <v-col cols="4">
              <v-select  :label="tLabel('Tipo Danno') + '*'" v-model="slotProps.data.tipoDanno"  :items="tipoDanni" :rules="[rules.required]" :error-messages="slotProps.errors.tipoDanno"/>
            </v-col>
            <v-col cols="4">
              <v-text-field  :label="tLabel('Codice Danno') + '*'" v-model="slotProps.data.codDanno" :rules="[rules.required]" :error-messages="slotProps.errors.codDanno"/>
            </v-col>
            <v-col cols="4">
              <v-text-field  :label="tLabel('Descrizione Danno') + '*'" v-model="slotProps.data.descDanno" :rules="[rules.required]" :error-messages="slotProps.errors.descDanno" />
            </v-col>
        </v-row>      
      </div>
    </template>
  </TosListVuetify>
</template>

<script>
import TosListVuetify from "../../components/anagrafiche/TosListVuetify";
import FormatsMixins from "../../mixins/FormatsMixins";

export default {
  name: "Danni",
  data() {
    return {
      entityName: "danni",
      tipoDanni: ["UTI","CARRO"],
      headers: [
        { text: "", value: "action" },
        { text: "Tipo Danno", value: "tipoDanno"},
        { text: "Codice Danno", value: "codDanno"},
        { text: "Descrizione Danno", value: "descDanno" },
      ],
    };
  },
  components: {
    TosListVuetify: TosListVuetify
  },
  mixins: [FormatsMixins],
  methods: {
    validate(item) {
       let errors = {};
       // TODO
       return errors;
    }
  },
};
</script>
